@import "../../base";

.home {
  position: relative;

  .parallax-bg {
    position: absolute;
    bottom:0;
    left:0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 55vh;
    background-image: url("/bg-3.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
    overflow: hidden;
    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-repeat: no-repeat;
      background-size:  100% auto;
      background-position: top center;
    }
    &:before {
      z-index: 2;
      background-image: url("/bg-2.svg");
      height: 55vh;
    }
    &:after {
      z-index: 3;
      background-image: url("/bg-1.svg");
      height: 38vh;
    }
  }

  section {
    position: relative;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    &.section-1 {
      background-position: center center;
      background-image: url("/bg.png");
      background-repeat: repeat-x;
      background-size: auto 100%;
      background-color: $base-color;
      color: #ffffff;
    }
    &.section-2 {
      background-color: #ffffff;
      color: $base-color;
    }
  }

}

