@import "./base.scss";

*,
*:before,
*:after {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -ms-box-sizing:border-box;
  -o-box-sizing:border-box;
  box-sizing:border-box;
  outline:none;
}
html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}
#root {
  min-height: 100vh;
}
code { font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;}

/* BASE */
body {
  color: $txt-color;
  font-size: $font-size;
  font-family: $font-family;
  background-color:#ffffff;
  @media all and (max-width:$bp-responsive) {
    font-size:14px;
  }
  @media all and (max-width:$bp-mobile) {
    font-size:12px;
  }
  @media all and (max-width:$bp-mobile-sm) {
    font-size:11px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
form,
section {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-header;
}
h1 {
  font-size: $font-size-xl;
  line-height:1.2em;
  @media all and (max-width:$bp-mobile-sm) {
    font-size:16px;
  }
}
a {
  color: $link-color;
  @include transition($animation);
  text-decoration: none;
}
.pointer {
  cursor: pointer;
}

/*special hover*/
a.hover {
  position: relative;
  display: inline-block;
  text-decoration: none;
  @include transition($animation);
  &:before {
    content: "";
    position: absolute;
    overflow: hidden;
    margin: auto;
    height: 1px;
    bottom: -3px;
    left: 0;
    right: 0;
    border-bottom: 1px solid $link-color;
    width: 0px;
    opacity: 0;
    @include transition($animation);
  }
  &:hover {
    text-decoration: none;
    @include transition($animation);
    &:before {
      width: 100%;
      opacity: 1;
    }
  }
}
/*end special hover*/

b,
.bold{
  font-weight: 600;
}
.animation {
  @include transition($animation);
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: inline-block;
}
/*end BASE*/



// /*LAYOUT*/

// .App-logo {
//   animation: App-logo-spin infinite 20s linear;
//   height: 80px;
// }


// @keyframes App-logo-spin {
//   from { transform: rotate(0deg); }
//   to { transform: rotate(360deg); }
// }
