// Fonts
$font-family: "Arvo", serif;
$font-family-header: "Roboto", sans-serif;

// Font-size
$font-size-xl: 2.5em;
$font-size-lg: 2em;
$font-size-md: 1.5em;
$font-size: 16px;
$font-size-sm: 0.9em;
$font-size-xs: 0.7em;
$font-size-xxs: 10px;

// Colors
$txt-color: #212529;
$link-color: #7b59a4;
$base-color: #7b59a4;

// Layout
/*
Typical resolutions for some popular screen sizes:
19-inch screen (standard ratio): 1280 x 1024 pixels.
20-inch screen (standard ratio): 1600 x 1200 pixels.
22-inch screen (widescreen): 1680 x 1050 pixels.
24-inch screen (widescreen): 1900 x 1200 pixels.
*/
$bp-desktop: 1900px;
$bp-laptop: 1440px;
$bp-responsive: 1280px;
$bp-tablet: 1024px;
$bp-mobile: 768px;
$bp-mobile-sm: 640px;

$animation: all 0.5s ease-in-out;

// cross-brawser styles

%flex {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
} //  @extend %flex;

// -webkit-transform: translateX(-50%) translateY(-50%);
// transform: translateX(-50%) translateY(-50%);

@mixin transition($property) {
    -webkit-transition: $property;
    -moz-transition: $property;
    -ms-transition: $property;
    -o-transition: $property;
    transition: $property;
} // .clasname { @include transition(all 0.5s ease-in-out); }

@mixin transform($property) {
    -webkit-transform: $property;
    -moz-transform: $property;
    -ms-transform: $property;
    -o-transform: $property;
    transform: $property;
} // @include transform(translateY(-50%));
